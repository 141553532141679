<template>
  <div class="content-info">
    <div class="partner-content">
       <div class="partner-item" v-for="(item,key) in partnerList" :key="key">
          <div class="partner-img-block"><img class="partner-img" :src="item.img" alt="合作伙伴" /></div>
        </div>
        <div style="clear:both;"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutPartner',
  data() {
    return {
      partnerList:[
        {
          img: require('@/assets/partner/1.png'),
        },
        {
          img: require('@/assets/partner/2.png'),
        },
        {
          img: require('@/assets/partner/3.png'),
        },
        {
          img: require('@/assets/partner/4.png'),
        },
        {
          img: require('@/assets/partner/5.png'),
        },
        {
          img: require('@/assets/partner/6.png'),
        },
         {
          img: require('@/assets/partner/7.png'),
        },
        {
          img: require('@/assets/partner/8.png'),
        },
        {
          img: require('@/assets/partner/9.png'),
        },
         {
          img: require('@/assets/partner/10.png'),
        },
         {
          img: require('@/assets/partner/11.png'),
        },
         {
          img: require('@/assets/partner/12.png'),
        },
         {
          img: require('@/assets/partner/13.png'),
        },
         {
          img: require('@/assets/partner/14.png'),
        },
         {
          img: require('@/assets/partner/15.png'),
        },
         {
          img: require('@/assets/partner/16.png'),
        },
         {
          img: require('@/assets/partner/17.png'),
        },
         {
          img: require('@/assets/partner/18.png'),
        },{
          img: require('@/assets/partner/19.png'),
        },
         {
          img: require('@/assets/partner/20.png'),
        },
        {
          img: require('@/assets/partner/21.png'),
        },
        {
          img: require('@/assets/partner/22.png'),
        },
         {
          img: require('@/assets/partner/23.png'),
        },
         {
          img: require('@/assets/partner/24.png'),
        },
         {
          img: require('@/assets/partner/25.png'),
        },
         {
          img: require('@/assets/partner/26.png'),
        },
         {
          img: require('@/assets/partner/27.png'),
        },
         {
          img: require('@/assets/partner/28.png'),
        },
         {
          img: require('@/assets/partner/29.png'),
        },
         {
          img: require('@/assets/partner/30.png'),
        },
         {
          img: require('@/assets/partner/31.png'),
        }, {
          img: require('@/assets/partner/32.png'),
        },
         {
          img: require('@/assets/partner/33.png'),
        },
         {
          img: require('@/assets/partner/34.png'),
        },
         {
          img: require('@/assets/partner/35.png'),
        },
        {
          img: require('@/assets/partner/36.png'),
        }
      ],
    }
  },
  methods: {
  }
}
</script>

<style scoped>
  .partner-content {
    padding:40px 10%;
  }

  .partner-item {
    display: flex;
    width: 16.6%;
    border: solid 0.026667rem #f5f5f5;
    position: relative;
    /* padding: 0vw 1vw; */
    /* transition: 0.3s; */
    align-items: center;
    justify-content: center;
    float: left;
  }

  .partner-img-block {
    height: 10vw;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .partner-img {
    max-width:100%;
    max-height:100%;
  }

  @media (max-width: 1024px) {
    .partner-item {
      width:33.3%;
    }

    .partner-img-block {
      height: 20vw;
    }

  }

  @media (max-width: 768px) {
    .partner-item {
      width:50%;
    }
    .partner-content {
      padding:10px;
    }
  }
</style>